import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

//Components
import Main from '../components/Servicios/main'
import Adminitration from '../components/Servicios/administration'
import BannerTop from "../components/Global/Modules/MediaPush/BannerTop";

const IndexPage = () => (
  <Layout>
    <Seo title="Tasaciones"/>
      <BannerTop page="TAS"/>
      <div className="tasaciones">
        <Main />
      </div>
  </Layout>
)

export default IndexPage
